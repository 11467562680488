import React from 'react'
import { string } from 'prop-types'
import showdown from 'showdown'

const converter = new showdown.Converter()

const MarkdownContent = ({ content, className }) => (
  <div
    className={className}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
  />
)

MarkdownContent.propTypes = {
  content: string.isRequired,
  className: string.isRequired,
}

export default MarkdownContent
