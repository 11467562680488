import React from 'react'
import { Formik, ErrorMessage } from 'formik'
import { Icon, Input } from 'antd'
import { Form } from '@jbuschke/formik-antd'
import * as Yup from 'yup'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { arrayOf, bool, func, string } from 'prop-types'
import theme from '../styles/theme'

const { Search } = Input

const SubscribeSchema = Yup.object().shape({
  email_address: Yup.string()
    .email('Invalid email address')
    .required('Required'),
})

function PostSubmissionMessage() {
  return (
    <div
      css={css`
        color: ${theme.colors.material.green.primary};
      `}
    >
      <p
        css={css`
          font-size: 1.2em;
          font-weight: 700;
        `}
      >
        <Icon
          type="check"
          style={{
            color: theme.colors.material.green.primary,
            marginRight: 10,
            verticalAlign: 'text-bottom',
          }}
        />
        Thanks for signing up!
      </p>
      <p>Go check your inbox now to confirm your email.</p>
    </div>
  )
}

const StyledForm = styled(Form)`
  label {
    margin: 10px 0;
  }

  .field-error {
    display: block;
    color: ${theme.colors.material.red.primary};
    padding-left: 10px;
  }

  input,
  label {
    width: 100%;
    font-size: 16px;
  }

  button {
    font-size: 16px;
  }
`

function fetchReducer(state, { type, response, error }) {
  switch (type) {
    case 'fetching': {
      return { error: null, response: null, pending: true }
    }
    case 'success': {
      return { error: null, response, pending: false }
    }
    case 'error': {
      return { error, response: null, pending: false }
    }
    default:
      throw new Error(`Unsupported type: ${type}`)
  }
}

function useFetch({ url, body }) {
  const [state, dispatch] = React.useReducer(fetchReducer, {
    error: null,
    response: null,
    pending: false,
  })
  const bodyString = JSON.stringify(body)

  React.useEffect(() => {
    if (url && bodyString) {
      dispatch({ type: 'fetching' })
      fetch(url, {
        method: 'post',
        body: bodyString,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(r => r.json())
        .then(
          response => dispatch({ type: 'success', response }),
          error => dispatch({ type: 'error', error })
        )
    }
  }, [url, bodyString])

  return state
}

function Subscribe({ signedUp, tags, onSuccess }) {
  const [values, setValues] = React.useState()
  const { pending, response, error } = useFetch({
    url: `https://app.convertkit.com/forms/997411/subscriptions`,
    body: values,
  })

  const errorMessage = error ? 'Something went wrong!' : null
  const submitted = Boolean(response)

  const successful = signedUp || (response && response.status === 'success')
  if (successful) onSuccess()

  return (
    <div
      css={css`
        height: 60px;
        min-height: 60px;
      `}
    >
      {!successful && (
        <Formik
          initialValues={{
            email_address: '',
            tags,
          }}
          validationSchema={SubscribeSchema}
          onSubmit={setValues}
          render={({ handleChange, handleSubmit }) => {
            return (
              <StyledForm>
                <Search
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    input {
                      border: 2px solid ${theme.colors.blue};
                    }

                    button {
                      font-weight: 700;
                      letter-spacing: 0.2px;
                      text-transform: uppercase;
                    }
                  `}
                  id="email"
                  aria-required="true"
                  name="email_address"
                  placeholder="Your work email"
                  type="email"
                  size="large"
                  enterButton={
                    pending ? 'Requesting invite...' : 'Request invite'
                  }
                  onChange={handleChange}
                  onPressEnter={handleSubmit}
                  onSearch={handleSubmit}
                />
                <ErrorMessage
                  name="email_address"
                  component="span"
                  className="field-error"
                />
              </StyledForm>
            )
          }}
        />
      )}
      {submitted && !pending && <PostSubmissionMessage response={response} />}
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  )
}

Subscribe.defaultProps = {
  signedUp: false,
  tags: [],
  onSuccess: () => {},
}

Subscribe.propTypes = {
  signedUp: bool,
  tags: arrayOf(string),
  onSuccess: func,
}

export default Subscribe
