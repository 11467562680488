import React from 'react'
import { func, shape, string, node } from 'prop-types'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Content, { HTMLContent } from '../components/Content'
import BlogHeader from '../components/BlogHeader'
import MarkdownContent from '../components/MarkdownContent'
import Avatar from '../components/Avatar'
import SubscribeSection from '../components/SubscribeSection'

const Author = styled.div`
  display: flex;
  align-items: center;
  margin: 3em auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 100%;
  }
  @media (min-width: 992px) {
    flex: 0 0 66.66667%;
    margin-left: 16.66667%;
    max-width: 66.66667%;
  }

  strong {
    color: #212121;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }

  span {
    margin-left: 10px;
  }
`

const AuthorPhoto = styled.div`
  border-radius: 25px;
  height: 50px;
  max-height: 50px;
  width: 50px;
  max-width: 50px;
  overflow: hidden;
  margin-right: 10px;
`

export const BlogPostTemplate = ({
  content,
  contentComponent,
  credits,
  description,
  featuredImage,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="article-section">
        <div className="article-header">
          <h2 style={{ margin: '1em 0' }}>{title}</h2>
          {featuredImage && (
            <img
              className="article-featured-image"
              src={featuredImage}
              alt={title}
            />
          )}
        </div>
        <Author>
          <AuthorPhoto>
            <Avatar />
          </AuthorPhoto>
          <div>
            <div>
              <strong>Josh Smith</strong> &mdash; Founder at Sift
            </div>
            <div>
              <a href="https://www.twitter.com/joshsmith">@joshsmith</a>
            </div>
          </div>
        </Author>
        <h3 className="article-description">{description}</h3>
        <PostContent className="article" content={content} />
        <SubscribeSection />
        <MarkdownContent className="article-credits" content={credits} />
      </div>
    </section>
  )
}

BlogPostTemplate.defaultProps = {
  credits: null,
  description: null,
  featuredImage: null,
  helmet: null,
}

BlogPostTemplate.propTypes = {
  content: node.isRequired,
  contentComponent: func.isRequired,
  credits: string,
  description: string,
  featuredImage: string,
  title: string.isRequired,
  helmet: shape({}),
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout color="indigo">
      <BlogHeader empty />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        credits={post.frontmatter.credits}
        description={post.frontmatter.description}
        featuredImage={post.frontmatter.featuredImage}
        helmet={
          <Helmet titleTemplate="%s | Sift">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: shape({
    markdownRemark: shape({}),
  }).isRequired,
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredImage
        credits
      }
    }
  }
`
