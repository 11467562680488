import React from 'react'
import { node, string } from 'prop-types'

export const HTMLContent = ({ content, className }) => (
  // eslint-disable-next-line react/no-danger
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content, className }) => (
  <article className={className}>{content}</article>
)

Content.propTypes = {
  content: node.isRequired,
  className: string.isRequired,
}

HTMLContent.propTypes = Content.propTypes

export default Content
