import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { Divider } from 'antd'
import styled from '@emotion/styled'
import { bool } from 'prop-types'
import FeaturesListItem from './FeaturesListItem'
import Feedback from '../images/feedback.svg'
import Users from '../images/users.svg'
import Debugger from '../images/debugger.svg'
import theme from '../styles/theme'

const Inner = styled.div`
  padding: 10px;
  width: 300px;

  @media (max-width: 669px) {
    padding: 0;
    width: 100%;
  }
`

const FeaturesList = ({ reduced }) => (
  <Inner>
    <Link to="/#product-feedback">
      <FeaturesListItem
        color={theme.colors.blue}
        icon={<Feedback />}
        title="Product Feedback"
        description="Collect feedback from your users right inside your product."
      />
    </Link>
    <Link to="/#user-insights">
      <FeaturesListItem
        color={theme.colors.material.deeppurple.primary}
        icon={<Users />}
        title="User Insights"
        description="See detailed profiles of your users, their usage, and their feedback."
      />
    </Link>
    {!reduced && (
      <Fragment>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <FeaturesListItem
          color={theme.colors.material.grey['900']}
          icon={<Debugger />}
          small
          title="Live Debugger"
          description="Check your data in real-time."
        />
      </Fragment>
    )}
  </Inner>
)

FeaturesList.defaultProps = {
  reduced: false,
}

FeaturesList.propTypes = {
  reduced: bool,
}

export default FeaturesList
