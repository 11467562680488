import React from 'react'
import styled from '@emotion/styled'
import { darken } from 'polished'
import { bool, node, string } from 'prop-types'

const FeedbackLink = styled.span`
  display: flex;
  padding: 10px 0;

  svg {
    .background,
    .foreground {
      transition: fill 0.2s;
    }
  }

  h3,
  p {
    transition: color 0.2s;
  }

  p {
    color: rgba(0, 0, 0, 0.65);
  }

  &:hover {
    svg {
      .background {
        fill: ${props => props.color};
      }

      .foreground {
        fill: white;
      }
    }

    h3 {
      color: ${props => darken(0.4, props.color)};
    }

    p {
      color: ${props => darken(0.1, props.color)};
    }
  }
`

const FeatureContent = styled.div`
  flex: 1;
  margin-left: 10px;
`

const FeatureTitle = styled.h3`
  color: ${props => props.color};
  font-size: ${props => (props.small ? '1.1em' : '1.2em')};
  font-weight: 600;
  margin-bottom: 5px;
`

const FeatureDescription = styled.p`
  font-size: 13px;
  margin: 0;
`

const FeaturesListItem = ({ color, icon, small, title, description }) => (
  <FeedbackLink color={color}>
    {icon}
    <FeatureContent>
      <FeatureTitle color={color} small={small}>
        {title}
      </FeatureTitle>
      <FeatureDescription>{description}</FeatureDescription>
    </FeatureContent>
  </FeedbackLink>
)

FeaturesListItem.defaultProps = {
  small: false,
}

FeaturesListItem.propTypes = {
  color: string.isRequired,
  description: string.isRequired,
  icon: node.isRequired,
  small: bool,
  title: string.isRequired,
}

export default FeaturesListItem
