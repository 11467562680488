import styled from '@emotion/styled'

const Container = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  width: 100%;
`

export default Container
