/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Layout as AntLayout } from 'antd'
import Header from './header'
import '../styles/layout.scss'

const { Content } = AntLayout

const GlobalContent = styled.div`
  margin: 0 auto;
`

const Layout = ({ children, color }) => (
  <AntLayout style={{ background: 'transparent' }}>
    <Content>
      <Header color={color} />
      <GlobalContent>
        <main>{children}</main>
      </GlobalContent>
    </Content>
  </AntLayout>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
