import React from 'react'
import styled from '@emotion/styled'
import { bool } from 'prop-types'
import Container from './Container'
import HeroImage from '../images/peak_mountain_3.svg'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: skewY(-5deg);
  transform-origin: 0;
  background: linear-gradient(150deg, #ede7f6 15%, #e8eaf6 70%, #e3f2fd 90%);
  top: 0;
`

const Hero = styled.div`
  display: flex;
  height: 300px;
  align-items: center;
`

const HeroInner = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeroContent = styled.div`
  flex: 55%;
  color: #1a237e;
`

const HeroImageContainer = styled.div`
  position: absolute;
  right: 0;
  width: 35%;
  text-align: right;
`

const BlogHeader = ({ empty }) => (
  <header style={{ position: 'relative' }}>
    <Background />
    <Hero>
      <Container>
        <HeroInner>
          {!empty && (
            <>
              <HeroContent>
                <h1 style={{ fontSize: '2.5em', textAlign: 'center' }}>
                  <strong>The Sift blog</strong>
                </h1>
                <p style={{ fontSize: '1.3em', textAlign: 'center' }}>
                  Thoughts on customer development, product management, and
                  more.
                </p>
              </HeroContent>
              {false && (
                <>
                  <div style={{ flex: '35%' }} />
                  <HeroImageContainer>
                    <HeroImage style={{ maxWidth: 340 }} />
                  </HeroImageContainer>
                </>
              )}
            </>
          )}
        </HeroInner>
      </Container>
    </Hero>
  </header>
)

BlogHeader.defaultProps = {
  empty: false,
}

BlogHeader.propTypes = {
  empty: bool,
}

export default BlogHeader
