import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Divider, Modal, Popover } from 'antd'
import styled from '@emotion/styled'
import { string } from 'prop-types'
import Container from './Container'
import FeaturesList from './FeaturesList'
import Logo from '../images/sift-logo.svg'
import theme from '../styles/theme'

const HEIGHT = '50px'

const StyledHeader = styled.header`
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  height: ${HEIGHT};
  z-index: 500;
`

const StyledNavbar = styled.nav`
  align-items: center;
  justify-content: space-between;
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
`

const LogoNavItem = styled.li`
  position: absolute;
  top: 0;
  left: 20px;
  display: flex;
  user-select: none;
`

const LogoLink = styled(Link)`
  min-height: ${HEIGHT};

  &:hover {
    color: hsla(0, 0%, 100%, 0.5);
  }

  h1 {
    line-height: ${HEIGHT};
    font-size: 20px;
    margin: 0;
    color: inherit;
  }

  svg #b,
  svg path {
    fill: ${props =>
      props.color ? theme.colors.material[props.color]['500'] : 'inherit'};
  }
`

const PrimaryNav = styled.li`
  display: flex;
  font-size: 16px;
  justify-content: center;

  @media (max-width: 669px) {
    display: none;
  }
`

const SecondaryNav = styled.li`
  position: absolute;
  font-size: 16px;
  font-weight: 300;
  top: 0;
  right: 0;
  justify-content: flex-end;

  @media (max-width: 669px) {
    display: none;
  }
`

const NavButton = styled.button`
  background: none;
  border: none;
  color: ${props =>
    props.color ? theme.colors.material[props.color]['500'] : 'inherit'};
  height: ${HEIGHT};
  line-height: ${HEIGHT};
  outline: none;
  user-select: none;

  &:hover {
    color: ${props =>
      props.color ? theme.colors.material[props.color]['700'] : 'inherit'};
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 670px) {
    padding: 0 calc(10px + (100vw - 670px) * 0.040541);
  }

  @media (min-width: 1040px) {
    padding: 0 25px;
  }

  @media (max-width: 669px) {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
  }
`

const NavGatsbyLink = styled(Link)`
  height: ${HEIGHT};
  line-height: ${HEIGHT};
  user-select: none;

  color: ${props =>
    props.color ? theme.colors.material[props.color]['500'] : 'inherit'};

  &:hover {
    color: ${props =>
      props.color ? theme.colors.material[props.color]['700'] : 'inherit'};
  }

  @media (min-width: 670px) {
    padding: 0 calc(10px + (100vw - 670px) * 0.040541);
  }

  @media (min-width: 1040px) {
    padding: 0 25px;
  }

  @media (max-width: 669px) {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
  }
`

const NavLink = styled.a`
  height: ${HEIGHT};
  line-height: ${HEIGHT};
  user-select: none;

  color: ${props =>
    props.color ? theme.colors.material[props.color]['500'] : 'inherit'};

  &:hover {
    color: ${props =>
      props.color ? theme.colors.material[props.color]['700'] : 'inherit'};
  }

  @media (min-width: 670px) {
    padding: 0 calc(10px + (100vw - 670px) * 0.040541);
  }

  @media (min-width: 1040px) {
    padding: 0 25px;
  }

  @media (max-width: 669px) {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
  }
`

const MobileNav = styled.li`
  display: none;

  @media (max-width: 669px) {
    display: flex;
  }

  justify-content: flex-end;

  h2 {
    font-size: 0;
    left: 13px;
    top: 23px;
  }

  h2:before {
    content: '';
    left: 0;
    top: -9px;
  }
  h2:after {
    content: '';
    left: 0;
    top: 9px;
  }

  h2,
  h2:before,
  h2:after {
    position: absolute;
    width: 24px;
    height: 3px;
    border-radius: 1px;
    background: currentColor;
  }

  button {
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: relative;
    padding: 0 30px;
  }
`

const MobilePrimaryNav = styled.div`
  display: flex;
  font-size: 16px;

  ul {
    display: flex;
    flex: 50%;
    flex-direction: column;
    margin: 10px;
    padding: 0;
  }

  li {
    line-height: 40px;
  }
`

const MobileSecondaryNav = styled.li`
  display: flex;
  font-size: 16px;
`

const MenuTitle = styled.h3`
  color: ${theme.colors.material.grey.primary};
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
`

const MobileModal = styled(Modal)`
  @media (min-width: 670px) {
    display: none;
  }
`

const Header = ({ color }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <StyledHeader>
      <Container style={{ padding: 0 }}>
        <StyledNavbar>
          <NavList>
            <LogoNavItem>
              <LogoLink color={color} to="/">
                <h1>
                  <Logo />
                </h1>
              </LogoLink>
            </LogoNavItem>
            <PrimaryNav>
              <Popover
                arrowPointAtCenter
                mouseEnterDelay={0}
                content={<FeaturesList reduced />}
              >
                <NavGatsbyLink color={color} to="/">
                  Features
                </NavGatsbyLink>
              </Popover>
              <NavGatsbyLink color={color} to="/pricing">
                Pricing
              </NavGatsbyLink>
              <NavLink color={color} href="https://docs.hellosift.com">
                Developers
              </NavLink>
              <NavGatsbyLink color={color} to="/blog">
                Blog
              </NavGatsbyLink>
            </PrimaryNav>
            <SecondaryNav>
              <NavLink color={color} href="https://support.hellosift.com">
                Support
              </NavLink>
              <NavLink color={color} href="https://app.hellosift.com">
                Sign in &rarr;
              </NavLink>
            </SecondaryNav>
            <MobileNav>
              <NavButton color={color} onClick={() => setIsVisible(true)}>
                <h2>Menu</h2>
              </NavButton>
            </MobileNav>
            <MobileModal
              visible={isVisible}
              onCancel={() => setIsVisible(false)}
              footer={null}
              mask={false}
              style={{
                top: 0,
                minWidth: 'calc(100% - 20px)',
                padding: 0,
              }}
            >
              <div style={{ padding: '30px 20px 0 20px' }}>
                <MenuTitle>Features</MenuTitle>
                <FeaturesList reduced />
              </div>
              <Divider style={{ margin: '10px 0 0 0' }} />
              <MobilePrimaryNav>
                <ul>
                  <li>
                    <NavGatsbyLink color={color} to="/pricing">
                      Pricing
                    </NavGatsbyLink>
                  </li>
                  <li>
                    <NavLink color={color} href="https://docs.hellosift.com">
                      Documentation
                    </NavLink>
                  </li>
                </ul>
                <ul>
                  <li>
                    <NavLink color={color} href="https://support.hellosift.com">
                      Support
                    </NavLink>
                  </li>
                  <li>
                    <NavGatsbyLink color={color} to="/blog">
                      Blog
                    </NavGatsbyLink>
                  </li>
                </ul>
              </MobilePrimaryNav>
              <MobileSecondaryNav>
                <NavLink
                  color={color}
                  href="https://app.hellosift.com"
                  style={{
                    background: theme.colors.material.bluegrey['50'],
                    height: '50px',
                    lineHeight: '50px',
                    padding: '0 20px',
                    width: '100%',
                  }}
                >
                  Sign in &rarr;
                </NavLink>
              </MobileSecondaryNav>
            </MobileModal>
          </NavList>
        </StyledNavbar>
      </Container>
    </StyledHeader>
  )
}

Header.defaultProps = {
  color: 'blue',
}

Header.propTypes = {
  color: string,
}

export default Header
