import React from 'react'
import { Col, Row } from 'antd'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { string, shape, arrayOf } from 'prop-types'
import Container from './Container'
import theme from '../styles/theme'
import Subscribe from './Subscribe'

const SubscribeFormWrapper = styled.div({
  borderRadius: 5,
  padding: '6em 4em',
  background: 'linear-gradient(150deg,#e8eaf6 15%,#e3f2fd 70%,#e1f5fe 90%)',
})

function SubscribeSection({ style, tags }) {
  return (
    <Container>
      <SubscribeFormWrapper style={style}>
        <Row>
          <Col md={{ span: 12 }}>
            <h3
              css={css`
                font-size: 2.25em;
                font-weight: 700;
              `}
            >
              Request an invite to Sift
            </h3>

            <p
              css={css`
                color: ${theme.colors.black};
                font-size: 1.2em;
                margin-bottom: 0;
              `}
            >
              Sift is invite-only right now. Sign up to be invited.
            </p>
          </Col>
          <Col
            md={{ span: 12 }}
            css={css`
              margin-top: 2em;
            `}
          >
            <Subscribe tags={tags} />
          </Col>
        </Row>
      </SubscribeFormWrapper>
    </Container>
  )
}

SubscribeSection.defaultProps = {
  style: null,
  tags: [],
}

SubscribeSection.propTypes = {
  style: shape({}),
  tags: arrayOf(string),
}

export default SubscribeSection
